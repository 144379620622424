export enum LocationType {
  PlayerInfluenceZone = 1,
  Council,
  PlayerThrone,
  PlayerCastle,
  CastleStock,
  BannerDeck,
  Discard,
  AlkaneSquare,
  PlayerHand,
  PlayerTitan,
  Help
}
