export enum RuleId {
  DrawBannerCard = 1,
  ChooseAlkaneColor,
  ChooseAction,
  Influence,
  Recruit,
  RefillAlkane,
  Sorcerer,
  Warrior,
  EndGame,
  ActivateCharacter
}
